import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTiktok,
  faDiscord,
  faReddit,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

import "../../styles/General/footer.scss";

const Footer = () => {
  return (
    <div className="footerCont">
      <div className="footerRow">
        <div className="footerLinkSection">
          <p className="footerLinkHeader">Links</p>
          <Link to="/" className="footerLink">
            Home
          </Link>
          <Link to="/#homeAbout" className="footerLink">
            How It Works
          </Link>
          <Link to="/#homeFAQ" className="footerLink">
            FAQ
          </Link>
        </div>
        <div className="footerLinkSection">
          <p className="footerLinkHeader">Contact</p>
          <Link
            to="mailto:info@tradeupfinder.com?subject=Contact from website"
            className="footerLink"
          >
            Email
          </Link>
          <Link
            to="mailto:info@tradeupfinder.com?subject=Support from website"
            className="footerLink"
          >
            Support
          </Link>
        </div>
        <div className="footerLinkSection">
          <p className="footerLinkHeader">Social</p>
          <div className="footerSocialIcons">
            <a
              href="https://www.tiktok.com/@tradeupfinder"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTiktok} />
            </a>
            <a
              href="https://discord.gg/YhkXGbah"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faDiscord} />
            </a>
            <a
              href="https://www.reddit.com/r/tradeupfinder/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faReddit} />
            </a>
            <a
              href="https://x.com/tradeupfinder"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
          </div>
        </div>
        <div className="footerLinkSection">
          <p className="footerLinkHeader">Legal</p>
          <Link to="/privacy-policy" className="footerLink">
            Privacy Policy
          </Link>
          <Link to="/terms-of-service" className="footerLink">
            Terms of Service
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;

/*
          <Link to="/subscribe" className="footerLink">
            Pricing
          </Link>
*/
