import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import {
  RENDER_HOWITWORKS_SECTION,
  RENDER_FAQ_SECTION,
} from "../utils/AboutSections.utils";
import { isUserSubscribed } from "../utils/General.utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";

import "../styles/home.scss";

function Home() {
  const navigate = useNavigate();
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    const checkIsSubbed = async () => {
      const isSubbed = await isUserSubscribed();
      setIsSubscribed(isSubbed);
    };

    checkIsSubbed();
  }, []);

  return (
    <div className="homepageCont">
      <div className="homeHeroSection">
        <div className="homeTitleCont">
          <h1>TRANSFORM YOUR CS2 SKINS INTO PROFITABLE TRADE-UPS</h1>
          <h2>
            Discover the best trade up opportunities with the advanced AI and
            market analysis of TradeUpFinder.
          </h2>
          <div className="homeCTA">
            <Button
              variant="secondary"
              size="lg"
              onClick={() => {
                isSubscribed ? navigate("/trade-up") : navigate("/subscribe");
              }}
            >
              Trade Now
            </Button>
          </div>
        </div>
        <></>
      </div>
      {RENDER_HOWITWORKS_SECTION()}
      <div className="homeAboutSection" id="homeAbout">
        <div className="homeAboutText">
          <h2 className="homeAboutTextTitle">
            Unlock the Full Potential of Your Skins
          </h2>
          <p>
            You know about trade-up contracts, but the mechanics behind them are
            complex. With endless guides, posts, and videos, it's hard to find
            reliable trade-ups. Often, by the time you act, the market has
            changed. Why rely on outdated information when you can use live data
            and AI to do the work for you?
          </p>
        </div>
        <div className="homeAboutImg">
          <img
            src="https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/homepage/Personalized.png"
            alt="Personalized"
          />
        </div>
      </div>
      <div className="homeAboutSection">
        <div className="homeAboutImg">
          <img
            src="https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/homepage/Real-Time_Data.png"
            alt="Real-time data"
          />
        </div>
        <div className="homeAboutText">
          <h2 className="homeAboutTextTitle">Real-Time Data Analysis</h2>
          <p>
            Stay ahead of the market with up-to-date information. Our platform
            analyzes current market pricing to provide the most profitable
            trade-up opportunities.
          </p>
        </div>
      </div>
      <div className="homeAboutSection">
        <div className="homeAboutText">
          <h2 className="homeAboutTextTitle">
            AI-Powered Trade-Up Suggestions - Accurate & profitable
          </h2>
          <p>
            Our advanced AI analyzes your input criteria to generate the best
            trade-up suggestions. No more guesswork or outdated guides. Find
            endless amount of trade ups that are actually profitable.
          </p>
          <h2 className="homeAboutTextTitle">Personalized Trade Management</h2>
          <p>
            Customize your trade-ups by selecting skin rarity, StatTrak™
            variants, collections, average float, and minimum float. Save and
            track your trades effortlessly.
          </p>
        </div>
        <div className="homeAboutImg">
          <img
            src="https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/homepage/AI-Powered.png"
            alt="AI-Powered"
          />
        </div>
      </div>
      <div className="homeCTASection">
        <h2 className="homeCTATitle">Ready to Transform Your Skins?</h2>
        <h3>
          Join thousands of CS2 players already profiting with TradeUpFinder Pro
        </h3>
        <Button
          className="homeCTABtn"
          variant="secondary"
          size="lg"
          onClick={() => navigate("/trade-up")}
        >
          Trade Now
        </Button>
      </div>
      {RENDER_FAQ_SECTION()}
      <div className="homeTestimonialSection">
        <div className="homeTestimonial">
          <div className="homeTestimonialText">
            <FontAwesomeIcon
              className="homeTestimonialIcon"
              icon={faCircleUser}
            />
            <h4>
              "TradeUpFinder Pro has revolutionized how I trade CS2 skins. The
              real-time data and AI-driven insights have made all the
              difference."
            </h4>
          </div>
          <p>- Alex, Pro User</p>
        </div>
        <div className="homeTestimonial">
          <div className="homeTestimonialText">
            <FontAwesomeIcon
              className="homeTestimonialIcon"
              icon={faCircleUser}
            />
            <h4>
              "No more guesswork or outdated guides. TradeUpFinder Pro gives me
              the confidence to trade smartly and profitably."
            </h4>
          </div>
          <p>- Jamie, Enthusiast Trader</p>
        </div>
      </div>
    </div>
  );
}

export default Home;

/*
<Button
          className="homeCTABtn"
          variant="secondary"
          size="lg"
          onClick={() =>
            isSubscribed ? navigate("/trade-up") : navigate("/subscribe")
          }
        >
          {isSubscribed ? "Trade Now" : "Subscribe to Pro"}
        </Button>
*/
