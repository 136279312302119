import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { isUserLoggedIn } from "../utils/General.utils";

const HeaderAuthBtn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoggedIn(isUserLoggedIn());
  }, [location]);

  const triggerLogout = (e) => {
    e.preventDefault();
    axios
      .get(`${process.env.REACT_APP_API_URL}/userLogout`)
      .then(() => {
        setIsLoggedIn(false);
      })
      .catch((e) => console.log("Server error logging user out", e))
      .finally(() => {
        localStorage.removeItem("authToken");
        navigate("/");
      });
  };

  const userDetailsMenu = (
    <Popover id="popover-basic" className="userDetailsMenu">
      <Popover.Body className="userDetailsMenuBody">
        <h4>My Profile</h4>
        <p className="userDetailsMenuItem" onClick={() => navigate("/profile")}>
          Profile
        </p>
        <p className="userLogoutBtn" onClick={(e) => triggerLogout(e)}>
          Logout
        </p>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {isLoggedIn ? (
        <Button
          className="headerSignupBtn"
          variant="secondary"
          onClick={(e) => triggerLogout(e)}
          size="sm"
        >
          Log out
        </Button>
      ) : (
        <LinkContainer to="/login">
          <Button className="headerSignupBtn" variant="secondary" size="sm">
            Login
          </Button>
        </LinkContainer>
      )}
    </>
  );
};

export default HeaderAuthBtn;

/* Unused profile dropdown

<OverlayTrigger
          trigger="click"
          placement="bottom"
          overlay={userDetailsMenu}
          rootClose
        >
          <Button
            className="headerSignupBtn userDetailsBtn"
            variant="secondary"
            size="md"
          >
            <FontAwesomeIcon icon={faUser} />
          </Button>
        </OverlayTrigger>
*/
