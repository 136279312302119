import "./App.scss";
import axios from "axios";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import ResetPW from "./pages/Auth/ResetPW";

import Profile from "./pages/UserProfileSimplified";
import Subscribe from "./pages/Subscribe";
import Checkout from "./pages/Checkout/Checkout";
import CheckoutSuccess from "./pages/Checkout/CheckoutSuccess";

import Homepage from "./pages/Home";
import Calculator from "./pages/Calculator";
import TradeupController from "./pages/TradeUp/TradeupController";

import ScrollToTop from "./utils/ScrollToTop";
import Header from "./components/General/Header";
import Footer from "./components/General/Footer";

import PrivacyPolicy from "./pages/Legal/PrivacyPolicy";
import TermsOfService from "./pages/Legal/TermsOfService";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Protect route to ensure it can be accessed only via redirect
const ProtectedRedirectRoute = ({ element: Element, redirectVal }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirect = searchParams.get(redirectVal);

  return redirect === "true" ? <Element /> : <Navigate to="/" />;
};

function App() {
  return (
    <div className="App">
      <div className="siteBG">
        <div className="siteCont">
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/" element={<Homepage />}>
              <Route index element={<Homepage />} />
            </Route>
            <Route exact path="trade-up" element={<TradeupController />} />
            <Route exact path="terms-of-service" element={<TermsOfService />} />
            <Route exact path="privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="login" element={<Login />} />
            <Route exact path="signup" element={<Signup />} />
            <Route path="resetPW/*" element={<ResetPW />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;

/* Unused routes

<Route exact path="calculator" element={<Calculator />} />

<Route exact path="profile" element={<Profile />} />
<Route exact path="subscribe" element={<Subscribe />} />
<Route path="checkout/:selectedPlan" element={<Checkout />} />
            <Route path="return/" element={<CheckoutSuccess />} />
<Route
              path="/resetPW"
              element={
                <ProtectedRedirectRoute
                  element={<ResetPW />}
                  redirectVal="password_reset_confirm"
                />
              }
            />

*/
