import React from "react";
import TradeupPro from "./TradeupPro";
import TradeupProSimplified from "./TradeupProSimplified";

/*
import React, { useState, useEffect } from "react";
import axios from "axios";
import TradeupPreview from "./TradeupPreview";
import Spinner from "react-bootstrap/Spinner";
import { isUserLoggedIn } from "../../utils/General.utils";
*/

const TradeupController = () => {
  /*
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [sampleData, setSampleData] = useState({});

 
  useEffect(() => {
    if (!isUserLoggedIn()) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/getExampleTradeData`)
        .then((res) => {
          setIsSubscribed(false);
          setSampleData(res.data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log("Error fetching trade data for unauthenticated user", e);
        });
    } else {
      axios
        .get(`${process.env.REACT_APP_API_URL}/isUserSubscribed`)
        .then((res) => {
          const isUserSubscribed = res.data.subscribed;
          setIsSubscribed(isUserSubscribed);
          if (!isUserSubscribed) {
            setSampleData(res.data.example_data);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          console.log("Error fetching subscription data", e);
        });
    }
  }, []);

  {isLoading ? (
    <div className="tradeControllerLoaderCont">
      <Spinner animation="border" variant="light" />
      <h1 className="tradeControllerLoaderText">Loading...</h1>
    </div>
  ) : isSubscribed ? (
    <TradeupPro />
  ) : (
    <TradeupPreview sampleData={sampleData} />
  )}
    */

  return (
    <>
      <TradeupProSimplified />
    </>
  );
};

export default TradeupController;
